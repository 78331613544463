<template>
  <mega-card>
    <div class="card-body p-4">
      <mega-switch
        class="w-100 mb-0 bg-success mt-3"
        :label="$t('round_top_forall')"
        @click="toggleForAll"
        :checked="topForAll"
      />
      <mega-input
        class="mb-0 mt-3"
        type="number"
        :label="$t('round_likes_label')"
        v-model="likesAmmount"
        :placeholder="$t('round_likes_input')"
      />
      <mega-select
        class="mb-0 mt-3"
        :label="$t('round_top_sex')"
        :placeholder="$t('round_top_not_selected')"
        :disabled="topForAll"
        v-model="topSex"
        :options="[
          { value: 1, name: $t('round_top_not_selected') },
          { value: 2, name: $t('round_top_sex_f') },
          { value: 3, name: $t('round_top_sex_m') }
        ]"
      />
      <mega-select
        class="mb-0 mt-3"
        :label="$t('round_top_age')"
        :placeholder="$t('round_top_not_selected')"
        :disabled="topForAll"
        v-model="topAgeType"
        :options="[
          { value: 1, name: $t('round_top_not_selected') },
          { value: 2, name: $t('round_top_age_exact') },
          { value: 3, name: $t('round_top_age_range') }
        ]"
      />
      <div :class="`d-flex ${topAgeType > 1 ? 'mt-3' : ''}`">
        <mega-input
          @input="alignSecondInput"
          v-if="topAgeType > 1 && !topForAll"
          type="number"
          :min="3"
          :max="100"
          v-model="topAgeArray.left"
        />
        <mega-input
          class="ml-2"
          v-if="topAgeType === 3 && !topForAll"
          type="number"
          :min="3"
          :max="100"
          v-model="topAgeArray.right"
        />
      </div>
      <mega-select
        class="mb-0 mt-3"
        :label="$t('round_top_loc')"
        :placeholder="$t('round_top_not_selected')"
        v-model="topLocationType"
        :disabled="topForAll"
        :options="[
          { value: 1, name: $t('round_top_not_selected') },
          { value: 2, name: $t('round_top_loc_city') },
          { value: 3, name: $t('round_top_loc_country') }
        ]"
      />
      <div class="d-flex flex-wrap justify-content-between mt-3">
        <mega-input
          v-if="topLocationType > 1 && !topForAll"
          class="w-50"
          v-model="topLocation"
        />
      </div>
    </div>
  </mega-card>
</template>

<script>
export default {
  props: ["round", "enabled", "likes"],
  data() {
    return {
      topForAll: true,
      topSex: 1,
      topAgeType: 1,
      topAgeArray: {
        left: 18,
        right: 18
      },
      topLocationType: 1,
      topLocation: "",
      likesAmmount: ""
    };
  },
  methods: {
    toggleForAll() {
      this.topForAll = !this.topForAll;
    },

    async submit() {
      const data = {
        idt_round: Number(this.round),
        for_all: this.topForAll,
        idc_sex: this.topSex,
        age:
          this.topAgeType > 1
            ? this.topAgeType > 2
              ? [Number(this.topAgeArray.left), Number(this.topAgeArray.right)]
              : [Number(this.topAgeArray.left), Number(this.topAgeArray.left)]
            : [],
        location_name: this.topLocation
      };

      this.$api.v2base
        .post("/round/explore/on_top", data)
        .then(() => {
          let params = {
            idt_round: Number(this.round),
            likes_count: Number(this.likesAmmount)
          };

          if (params.likes_count < 0) {
            this.$alert.danger(this.$t("round_likes_greater"));
            return;
          }
          if (params.likes_count) {
            this.$api.v2base
              .put("/round/likesCount", params)
              .then(() => {
                this.$alert.success("Успешно");
              })
              .catch(e => {
                console.log(e);
                this.$alert.danger("Server error");
              });
            this.modal = false;
            this.$router.push("/round/" + this.round);
          } else {
            this.modal = false;
            this.$router.push("/round/" + this.round);
          }
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    alignSecondInput() {
      if (this.topAgeType == 2) {
        this.topAgeArray.right = this.topAgeArray.left;
      } else {
        return;
      }
    }
  }
};
</script>
