<template>
  <mega-card>
    <div class="card-body p-4">
      <mega-switch
        class="w-100 mb-0 bg-success mt-3"
        :label="$t('advert_active')"
      />
      <mega-input
        class="mb-0 mt-3"
        type="number"
        :label="$t('advert_duration')"
        :placeholder="$t('advert_duration')"
      />
      <mega-select
        class="mb-0 mt-3"
        :label="$t('advert_place')"
        :placeholder="$t('round_top_not_selected')"
        :options="[
          { value: 1, name: $t('advert_on_app_enter') },
          { value: 2, name: $t('advert_on_feed') }
        ]"
      />
      <mega-range
        class="bg-primary mt-3"
        :label="$t('advert_frequency')"
        v-model="frequency"
        :min="1"
        :max="100"
        :step="1"
      />
      <mega-number
        :min="1"
        :max="100"
        :step="1"
        v-model="frequency"
        :help="frequency + '%'"
      />
    </div>
  </mega-card>
</template>

<script>
export default {
  props: ["round", "enabled", "likes"],
  data() {
    return {
      frequency: 1
    };
  },
  methods: {}
};
</script>
