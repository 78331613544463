var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mega-card", [
    _c(
      "div",
      { staticClass: "card-body p-4" },
      [
        _c("mega-switch", {
          staticClass: "w-100 mb-0 bg-success mt-3",
          attrs: { label: _vm.$t("round_top_forall"), checked: _vm.topForAll },
          on: { click: _vm.toggleForAll }
        }),
        _vm._v(" "),
        _c("mega-input", {
          staticClass: "mb-0 mt-3",
          attrs: {
            type: "number",
            label: _vm.$t("round_likes_label"),
            placeholder: _vm.$t("round_likes_input")
          },
          model: {
            value: _vm.likesAmmount,
            callback: function($$v) {
              _vm.likesAmmount = $$v
            },
            expression: "likesAmmount"
          }
        }),
        _vm._v(" "),
        _c("mega-select", {
          staticClass: "mb-0 mt-3",
          attrs: {
            label: _vm.$t("round_top_sex"),
            placeholder: _vm.$t("round_top_not_selected"),
            disabled: _vm.topForAll,
            options: [
              { value: 1, name: _vm.$t("round_top_not_selected") },
              { value: 2, name: _vm.$t("round_top_sex_f") },
              { value: 3, name: _vm.$t("round_top_sex_m") }
            ]
          },
          model: {
            value: _vm.topSex,
            callback: function($$v) {
              _vm.topSex = $$v
            },
            expression: "topSex"
          }
        }),
        _vm._v(" "),
        _c("mega-select", {
          staticClass: "mb-0 mt-3",
          attrs: {
            label: _vm.$t("round_top_age"),
            placeholder: _vm.$t("round_top_not_selected"),
            disabled: _vm.topForAll,
            options: [
              { value: 1, name: _vm.$t("round_top_not_selected") },
              { value: 2, name: _vm.$t("round_top_age_exact") },
              { value: 3, name: _vm.$t("round_top_age_range") }
            ]
          },
          model: {
            value: _vm.topAgeType,
            callback: function($$v) {
              _vm.topAgeType = $$v
            },
            expression: "topAgeType"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { class: `d-flex ${_vm.topAgeType > 1 ? "mt-3" : ""}` },
          [
            _vm.topAgeType > 1 && !_vm.topForAll
              ? _c("mega-input", {
                  attrs: { type: "number", min: 3, max: 100 },
                  on: { input: _vm.alignSecondInput },
                  model: {
                    value: _vm.topAgeArray.left,
                    callback: function($$v) {
                      _vm.$set(_vm.topAgeArray, "left", $$v)
                    },
                    expression: "topAgeArray.left"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.topAgeType === 3 && !_vm.topForAll
              ? _c("mega-input", {
                  staticClass: "ml-2",
                  attrs: { type: "number", min: 3, max: 100 },
                  model: {
                    value: _vm.topAgeArray.right,
                    callback: function($$v) {
                      _vm.$set(_vm.topAgeArray, "right", $$v)
                    },
                    expression: "topAgeArray.right"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("mega-select", {
          staticClass: "mb-0 mt-3",
          attrs: {
            label: _vm.$t("round_top_loc"),
            placeholder: _vm.$t("round_top_not_selected"),
            disabled: _vm.topForAll,
            options: [
              { value: 1, name: _vm.$t("round_top_not_selected") },
              { value: 2, name: _vm.$t("round_top_loc_city") },
              { value: 3, name: _vm.$t("round_top_loc_country") }
            ]
          },
          model: {
            value: _vm.topLocationType,
            callback: function($$v) {
              _vm.topLocationType = $$v
            },
            expression: "topLocationType"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-wrap justify-content-between mt-3" },
          [
            _vm.topLocationType > 1 && !_vm.topForAll
              ? _c("mega-input", {
                  staticClass: "w-50",
                  model: {
                    value: _vm.topLocation,
                    callback: function($$v) {
                      _vm.topLocation = $$v
                    },
                    expression: "topLocation"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }