var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "round-post-page" },
    [
      _c("section", [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-5 xl:col-3 mb-3" },
              [
                _c(
                  "div",
                  { staticStyle: { top: "85px" } },
                  [
                    _c(
                      "mega-card",
                      { staticClass: "card-round" },
                      [
                        _c(
                          "mega-drop-zone",
                          {
                            staticClass: "rounded-0",
                            attrs: { type: ["image/jpeg", "image/png"] },
                            on: { change: _vm.renderFile }
                          },
                          [
                            !_vm.preview
                              ? _c("mega-image", {
                                  attrs: { ratio: "9x16", src: _vm.preview }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.preview
                              ? _c("div", {
                                  staticClass: "w-100",
                                  class: { "opacity-0": _vm.rendering },
                                  style: `transition: .1s; height: 100%; padding-bottom: 177.777%; background-image: url(${this.preview}); background-size: cover; background-repeat: no-repeat; background-position: center`,
                                  attrs: { alt: "" }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("mega-switch", {
                      staticClass: "w-100 mt-3 bg-success",
                      attrs: { label: _vm.$t("round_to_top") },
                      model: {
                        value: _vm.toTop,
                        callback: function($$v) {
                          _vm.toTop = $$v
                        },
                        expression: "toTop"
                      }
                    }),
                    _vm._v(" "),
                    _c("to-top", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.toTop,
                          expression: "toTop"
                        }
                      ],
                      ref: "toTop",
                      attrs: { round: _vm.idt_round }
                    }),
                    _vm._v(" "),
                    _c("mega-switch", {
                      staticClass: "w-100 mt-2 bg-success",
                      attrs: { label: _vm.$t("advert_round") },
                      model: {
                        value: _vm.advertise,
                        callback: function($$v) {
                          _vm.advertise = $$v
                        },
                        expression: "advertise"
                      }
                    }),
                    _vm._v(" "),
                    _c("advertise", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.advertise,
                          expression: "advertise"
                        }
                      ],
                      ref: "advertise",
                      attrs: { round: _vm.idt_round }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "w-100",
                        staticStyle: { "text-align": "center" }
                      },
                      [
                        _c(
                          "mega-button",
                          {
                            staticClass: "btn-success",
                            attrs: {
                              loading: _vm.loading,
                              disabled: !_vm.valid
                            },
                            on: { click: _vm.submit }
                          },
                          [_vm._v(_vm._s(_vm.$t("round_post")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-7 xl:col-9" },
              [
                _c("mega-card", { attrs: { title: _vm.$t("round_opt") } }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("mega-select", {
                        staticStyle: { "z-index": "200" },
                        attrs: {
                          placeholder: _vm.$t("sel_user"),
                          label: _vm.$t("sel_user"),
                          filter: "",
                          options: _vm.users
                        },
                        on: { search: _vm.search },
                        model: {
                          value: _vm.round.idt_user,
                          callback: function($$v) {
                            _vm.$set(_vm.round, "idt_user", $$v)
                          },
                          expression: "round.idt_user"
                        }
                      }),
                      _vm._v(" "),
                      _c("mega-textarea", {
                        attrs: { label: _vm.$t("desc"), rows: "3", max: 2000 },
                        model: {
                          value: _vm.round.message,
                          callback: function($$v) {
                            _vm.$set(_vm.round, "message", $$v)
                          },
                          expression: "round.message"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "sm-down:col-12 md:col-4 lg:col-12 xl:col-4"
                          },
                          [
                            _c("mega-range", {
                              staticClass: "bg-primary mb-0",
                              attrs: {
                                label: _vm.$t("height"),
                                min: 10,
                                max: 3000,
                                step: 10
                              },
                              model: {
                                value: _vm.round.height,
                                callback: function($$v) {
                                  _vm.$set(_vm.round, "height", $$v)
                                },
                                expression: "round.height"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between text-muted"
                              },
                              [
                                _c("small", [_vm._v("10cm")]),
                                _vm._v(" "),
                                _c("small", [_vm._v("3000cm")])
                              ]
                            ),
                            _vm._v(" "),
                            _c("mega-number", {
                              attrs: { min: 10, max: 3000, step: 10 },
                              model: {
                                value: _vm.round.height,
                                callback: function($$v) {
                                  _vm.$set(_vm.round, "height", $$v)
                                },
                                expression: "round.height"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "sm-down:col-12 md:col-4 lg:col-12 xl:col-4"
                          },
                          [
                            _c("mega-range", {
                              staticClass: "bg-primary mb-0",
                              attrs: {
                                label: _vm.$t("round_plane_height"),
                                min: -1000,
                                max: 1000,
                                step: 0.01
                              },
                              model: {
                                value: _vm.round.ref_plane_height,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.round,
                                    "ref_plane_height",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "round.ref_plane_height"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between text-muted"
                              },
                              [
                                _c("small", [_vm._v("-1000m")]),
                                _vm._v(" "),
                                _c("small", [_vm._v("1000m")])
                              ]
                            ),
                            _vm._v(" "),
                            _c("mega-number", {
                              attrs: { min: -1000, max: 1000, step: 0.01 },
                              model: {
                                value: _vm.round.ref_plane_height,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.round,
                                    "ref_plane_height",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "round.ref_plane_height"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "map-card",
                  {
                    attrs: {
                      title: _vm.$t("round_pos"),
                      ratio: "16x9",
                      "get-location": "",
                      lat: _vm.round.lat,
                      lng: _vm.round.lng
                    },
                    on: { update: _vm.updatePosition }
                  },
                  [
                    _c("mega-switch", {
                      staticClass: "mb-0 w-100",
                      attrs: { label: _vm.$t("hide_loc") },
                      model: {
                        value: _vm.round.is_location_hidden,
                        callback: function($$v) {
                          _vm.$set(_vm.round, "is_location_hidden", $$v)
                        },
                        expression: "round.is_location_hidden"
                      }
                    }),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("hide_loc_desc")))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "mega-modal",
        {
          attrs: { active: _vm.modal, modal_dialog: "max-width: 50%;" },
          on: {
            onClose: function($event) {
              _vm.modal = false
            }
          }
        },
        [
          _c(
            "mega-card",
            {
              staticClass: "px-4 d-flex flex-column justify-content-center",
              attrs: { title: _vm.$t("crop_photo") }
            },
            [
              _c("Cropper", {
                ref: "cropper",
                staticClass: "mb-3 px-2",
                staticStyle: { "max-height": "500px" },
                attrs: {
                  src: _vm.preview,
                  stencilProps: {
                    aspectRatio: 9 / 16
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ml-auto mr-2" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn btn-light btn-sm",
                    on: {
                      click: function($event) {
                        _vm.modal = false
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("cancel")) + "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "btn btn-primary btn-sm ml-1",
                    on: { click: _vm.cropImage }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("confirm")) + "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }