var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mega-card", [
    _c(
      "div",
      { staticClass: "card-body p-4" },
      [
        _c("mega-switch", {
          staticClass: "w-100 mb-0 bg-success mt-3",
          attrs: { label: _vm.$t("advert_active") }
        }),
        _vm._v(" "),
        _c("mega-input", {
          staticClass: "mb-0 mt-3",
          attrs: {
            type: "number",
            label: _vm.$t("advert_duration"),
            placeholder: _vm.$t("advert_duration")
          }
        }),
        _vm._v(" "),
        _c("mega-select", {
          staticClass: "mb-0 mt-3",
          attrs: {
            label: _vm.$t("advert_place"),
            placeholder: _vm.$t("round_top_not_selected"),
            options: [
              { value: 1, name: _vm.$t("advert_on_app_enter") },
              { value: 2, name: _vm.$t("advert_on_feed") }
            ]
          }
        }),
        _vm._v(" "),
        _c("mega-range", {
          staticClass: "bg-primary mt-3",
          attrs: {
            label: _vm.$t("advert_frequency"),
            min: 1,
            max: 100,
            step: 1
          },
          model: {
            value: _vm.frequency,
            callback: function($$v) {
              _vm.frequency = $$v
            },
            expression: "frequency"
          }
        }),
        _vm._v(" "),
        _c("mega-number", {
          attrs: { min: 1, max: 100, step: 1, help: _vm.frequency + "%" },
          model: {
            value: _vm.frequency,
            callback: function($$v) {
              _vm.frequency = $$v
            },
            expression: "frequency"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }